import React from 'react'
import BackgroundPaper from './../Wrapper/BackgroundPaper'
import TitleText from './../Wrapper/TitleText'
import { Element } from 'react-scroll'
import { Grid, Typography } from '@mui/material'
import resume from '../../utils/resume'

const Award = () => {
    return (
        <Element name="refAwards" className="element"  >
            <BackgroundPaper elevation={8}>
                <TitleText component='h2' variant='h5' >Awards</TitleText>
                <Grid container spacing={2} >
                    {resume.awards.map((award, index) => (
                        <Grid item xs={12} key={index}>
                            <Typography component='h1' variant='h6'>{award.title}</Typography>
                            <Typography component='p' variant='p'>{award.issuer}</Typography>
                            <Typography component='p' variant='body2'>{award.issued}</Typography>
                            <Typography component='p' variant='body2'>{award.description}</Typography>
                        </Grid>
                    ))}
                </Grid>
            </BackgroundPaper>
        </Element>
    )
}

export default Award