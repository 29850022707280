import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const TitleText = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    marginBottom: 50,
    '&::after': {
        content: "''",
        background: theme.palette.error.main,
        borderRadius: '25px',
        display: 'block',
        height: '5px',
        mt: '14px',
        width: '40px',
    }
}))

export default TitleText