import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import NotFound from '../Errors/NotFound';
import resume from '../../utils/resume'
import { Stack } from '@mui/material';
import PortfolioDetail from '../../components/Sections/PortfolioDetail';
import AppBarBack from '../../components/Sections/AppBarBack';


const Portfolio = () => {
    const [loading, setLoading] = useState(true)
    const [content, setContent] = useState(null)
    const { slug } = useParams();

    useEffect(() => {
        const data = resume.portfolio.find(portfolio => portfolio.slug === slug)

        if (data)
            setTimeout(() => {
                setLoading(false)
                setContent(data)
            }, 500);
        else
            setLoading(false)
    }, [slug])

    return (
        <Stack spacing={3}>
            <AppBarBack />

            {!loading && !content ?
                <NotFound /> :
                <PortfolioDetail loading={loading} content={content} />}
        </Stack>
    )
}

export default Portfolio