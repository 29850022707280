import React from 'react'
import { Stack } from '@mui/system';
import ImageGallery from 'react-image-gallery';
import BackgroundPaper from '../Wrapper/BackgroundPaper';
import TitleText from '../Wrapper/TitleText';
import { Avatar, Skeleton, Tooltip, Typography } from '@mui/material';

const PortfolioDetail = ({ loading, content }) => {
    return (
        <BackgroundPaper elevation={8}>
            <TitleText component='h2' variant='h5'>{
                loading ?
                    <Skeleton variant="rectangular" width={210} height={35} /> :
                    content.title}
            </TitleText>

            <Stack spacing={2}>
                {loading ?
                    <Skeleton variant="rectangular" height={350} /> :
                    <ImageGallery
                        items={content.images}
                        showPlayButton={false}
                        showBullets={true}
                    />
                }
                {loading ?
                    <>
                        <Skeleton variant="rectangular" height={20} />
                        <Skeleton variant="rectangular" width={550} height={20} />
                    </> :
                    <Typography variant="body2" >
                        {content.description}
                    </Typography>
                }
                <Stack direction={'row'} spacing={1}>
                    {loading ?
                        <>
                            <Skeleton variant="circular" width={40} height={40} />
                            <Skeleton variant="circular" width={40} height={40} />
                        </> :
                        content.links.map((links, index) =>
                            <Tooltip title={links.name} arrow key={index}>
                                <Avatar sx={{ width: 40, height: 40, p: 0.5, cursor: 'pointer' }} alt={links.name} src={links.icon}>
                                    {links.name[0]}
                                </Avatar>
                            </Tooltip>
                        )
                    }
                </Stack>
            </Stack>
        </BackgroundPaper>
    )
}

export default PortfolioDetail