import { Grid, Typography, Stack, Box } from '@mui/material'
import React from 'react'
import BackgroundPaper from './../Wrapper/BackgroundPaper'
import TitleText from './../Wrapper/TitleText'
import resume from '../../utils/resume'
import { Element } from 'react-scroll'

const Contact = () => {
    return (
        <Element name="refContact" className="element"  >
            <BackgroundPaper elevation={8}>
                <TitleText component='h2' variant='h5' >Get in Touch</TitleText>
                <Typography variant="h6" color="white" sx={{ mb: 3 }}>Let's talk about everything!</Typography>

                <Grid container spacing={3}>
                    {resume.socials.map((social, index) => (
                        <Grid item xs={12} sm={6} key={index}>
                            <Stack direction={'row'} spacing={2}>
                                <Box width={45} height={45} padding={'5px'}>
                                    {social.icon}
                                </Box>
                                <Stack direction={'column'} spacing={0}>
                                    <Typography component='p' variant="h6" color="white" fontWeight={'bold'}>{social.name}</Typography>
                                    <Typography component='p' variant="p" color="white">{social.text}</Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
            </BackgroundPaper>
        </Element>
    )
}

export default Contact