import React from 'react'
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import BackgroundPaper from './../Wrapper/BackgroundPaper'
import TitleText from './../Wrapper/TitleText'
import { Typography } from '@mui/material';
import resume from '../../utils/resume'
import { Element } from 'react-scroll'

const Experience = () => {
    return (
        <Element name="refExperience" className="element"  >
            <BackgroundPaper elevation={8}>
                <TitleText component='h2' variant='h5' >Experience</TitleText>

                <Timeline sx={{ alignItems: 'left', pl: 1, pr: 0 }}>
                    {resume.experience.map((exp, index) => (
                        <TimelineItem key={index}>
                            <TimelineOppositeContent sx={{ flex: 0.3, pl: 0 }} color="white">
                                {exp.date}
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot variant='outline' sx={{ color: '#f97b8b' }} />
                                {index !== resume.experience.length - 1 ? <TimelineConnector sx={{ bgcolor: '#f97b8b' }} /> : ''}

                            </TimelineSeparator>
                            <TimelineContent color='white'>
                                <Typography component="h5" variant="h5" color="white" sx={{ fontWeight: 'bold' }}>{exp.company}</Typography>
                                <Typography component="h6" variant="h6" color="white">{exp.title}</Typography>
                                {exp.description.split(/\r?\n/).map(message => <Typography component="p" variant="p" color="#a6a3d1">{message}</Typography>)}

                                <Typography component="p" variant="p" color="#a6a3d1" sx={{ my: 1 }}>Skills: {exp.skills}</Typography>
                            </TimelineContent>
                        </TimelineItem>
                    ))}
                </Timeline>
            </BackgroundPaper>
        </Element>
    )
}

export default Experience