import { OpenInNew } from '@mui/icons-material'
import { Card, CardContent, Grid, Link, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react'
import BackgroundPaper from './../Wrapper/BackgroundPaper'
import TitleText from './../Wrapper/TitleText'
import resume from '../../utils/resume'
import { Element } from 'react-scroll'

const Certification = () => {
    return (
        <Element name="refCertifications" className="element"  >
            <BackgroundPaper elevation={8}>
                <TitleText component='h2' variant='h5' >Licenses & Certifications</TitleText>
                <Grid container spacing={2}>
                    {resume.certification.map((cert, idx) => (
                        <Grid item xs={12} sm={6} lg={6} key={idx} >
                            <Card sx={{ p: 0, borderRadius: '15px', boxShadow: 12, bgcolor: 'error.main', border: 'white solid 1px', }}>
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="p" variant="body1" sx={{ fontWeight: 'bolder' }}>
                                        {cert.organization}
                                    </Typography>
                                    <Typography variant="body2" component="p" sx={{ fontSize: 14, mt: 1 }} >
                                        {cert.title}
                                    </Typography>
                                </CardContent>

                                <Stack direction={'column'} sx={{ p: 2 }} spacing={1}>
                                    <Typography variant="p" component='p'>Issued {cert.issued} {cert.expires ? ` . Expires ${cert.expires}` : ''}</Typography>
                                    <Typography variant="p" component='p'>Credential ID {cert.credential_id}</Typography>
                                    {cert.credential_link ? (
                                        <Grid container direction='row' alignItems='center' >
                                            <Grid item>
                                                <Typography variant='link' sx={{ color: 'blueviolet', fontWeight: 'bolder', cursor: 'pointer' }} >
                                                    <Link href={cert.credential_link} target='__blank' color='inherit' sx={{ textDecoration: 'none' }}>
                                                        See credential
                                                    </Link>
                                                </Typography>
                                            </Grid>
                                            <Grid item sx={{ mx: 1, color: 'blueviolet' }}>
                                                <OpenInNew sx={{ fontSize: '15px', cursor: 'pointer' }} />
                                            </Grid>
                                        </Grid>
                                    ) : ''}
                                </Stack>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </BackgroundPaper>
        </Element>
    )
}

export default Certification