import { Stack, Typography } from '@mui/material'
import React from 'react'
import BackgroundPaper from '../../components/Wrapper/BackgroundPaper'

function NotFound() {
    return (
        <BackgroundPaper elevation={8} sx={{
            minHeight: 620,
            maxHeight: 620,
            justifyContent: 'center',
            alignItems: 'center',
        }} >

            <Stack spacing={1} sx={{ textAlign: 'center' }}>
                <Typography variant='h1' fontWeight='bolder'>404</Typography>
                <Typography variant='h2' fontWeight='bold'>Not Found</Typography>
            </Stack>
        </BackgroundPaper>
    )
}

export default NotFound