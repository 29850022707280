import { Box, Typography } from '@mui/material'
import React from 'react'

const ProfileText = ({ name, value }) => {
    return (
        <Typography component='p' variant="p" color='#a6a3d1'>
            {name}:
            <Box component="span" mx={1}>
                <Typography variant='p' color='white'>
                    {value}
                </Typography>
            </Box>
        </Typography>
    )
}

export default ProfileText