import { styled } from '@mui/material/styles'
import { Paper } from '@mui/material'

const BackgroundPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    borderRadius: 15,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 30
}))

export default BackgroundPaper