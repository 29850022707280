import React, { Fragment, useState } from 'react'
import { Avatar, Box, IconButton, List, ListItem, ListItemIcon, ListItemText, Stack, Tooltip, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import { Link as LinkScroll } from 'react-scroll'
import BackgroundPaper from '../Wrapper/BackgroundPaper'
import resume from '../../utils/resume'

const ButtonClose = styled(IconButton)(({ theme }) => ({
    p: 1,
    opacity: 1,
    position: 'absolute',
    zIndex: 1,
    right: 0,
    top: 0,
    marginTop: 16,
    marginRight: 18,
    backgroundColor: theme.palette.error.main,
    height: 28,
    width: 28,
}))

const ListItemMenu = styled(ListItem)({
    padding: 0,
    WebkitTransition: 'all 0.3s ease-in-out',
    MozTransition: 'all 0.3s ease-in-out',
    transition: 'all 0.3s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
        marginLeft: '10px'
    },
})

const Sidebar = ({ push, setPush }) => {
    const [active, setActive] = useState('refHome')

    const handlePush = () => {
        setPush(!push)
    }

    const handleActive = (to) => {
        setActive(to)
    }

    return (
        <BackgroundPaper elevation={8} sx={{
            zIndex: 2,
            justifyContent: 'start',
            minHeight: '620px',
            maxHeight: '620px',
            top: '50px',
            overflow: 'hidden',
            position: 'fixed',
            width: '256px',
            WebkitTransition: 'all 0.3s ease-in-out',
            MozTransition: 'all 0.3s ease-in-out',
            transition: 'all 0.3s ease-in-out',
            WebkitTransform: `translateX(${push ? '0' : '-289px'})`,
            MozTransform: `translateX(${push ? '0' : '-289px'})`,
            msTransform: `translateX(${push ? '0' : '-289px'})`,
            OTransform: `translateX(${push ? '0' : '-289px'})`,
            transform: `translateX(${push ? '0' : '-289px'})`,
        }}>

            <Box sx={{
                width: '100%',
                padding: '30px',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            }}>
                <ButtonClose sx={{ display: { md: 'inline-flex', lg: 'none' } }} onClick={handlePush}>
                    <Close fontSize='small' fontWeight='bolder' />
                </ButtonClose>

                <Box>
                    <Avatar sx={{ width: 70, height: 70, mb: 2 }} alt={resume.about.name} src={resume.about.foto}>A</Avatar>
                    <Typography variant="h5" component='h1' fontWeight='bold'>{resume.about.name}</Typography>
                    <Typography variant="body1" component='h2' >{resume.about.job}</Typography>
                </Box>
            </Box>

            <List sx={{ marginTop: '180px' }}>
                {resume.menu.map((menu, index) => (
                    <LinkScroll
                        activeClass="menu-active"
                        className={`ref${menu.title}`}
                        to={`ref${menu.title}`}
                        spy={true} smooth={true}
                        duration={700}
                        isDynamic={true}
                        key={index}
                        offset={-50}
                        onSetActive={handleActive}>
                        <ListItemMenu>
                            <ListItemIcon sx={{
                                '&::before': {
                                    content: "''",
                                    background: '#ff5959',
                                    display: 'block',
                                    height: '18px',
                                    left: '-30px',
                                    top: '7px',
                                    position: 'absolute',
                                    width: active === `ref${menu.title}` ? '4px' : '0'
                                }
                            }}>
                                {menu.icon}
                            </ListItemIcon>
                            <ListItemText primary={menu.title} />
                        </ListItemMenu>
                    </LinkScroll>
                ))}
            </List>

            <Stack spacing={1.5} direction='row' sx={{ marginTop: '110px' }}>
                {resume.socials.map((social, index) => {
                    if (!social.link)
                        return <Fragment key={index}></Fragment>

                    return (
                        <Tooltip arrow title={social.name} key={index}>
                            <a
                                href={`${social.link}${social.text}`}
                                rel="noreferrer"
                                target='_blank'
                                style={{ textDecoration: 'none' }}>

                                <Avatar sx={{
                                    bgcolor: 'error.main',
                                    color: 'common.white',
                                    width: '35px',
                                    height: '35px',
                                    p: '5px',
                                    '&:hover': {
                                        bgcolor: 'common.white',
                                        color: 'error.main'
                                    }
                                }}>
                                    {social.icon}
                                </Avatar>
                            </a>
                        </Tooltip>
                    )
                })}
            </Stack>


        </BackgroundPaper>
    )
}

export default Sidebar