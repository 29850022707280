import { styled } from '@mui/material/styles'
import { Button } from '@mui/material'

const ButtonWrapper = styled(Button)(({ theme }) => ({
    borderRadius: 25,
    color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    '&:hover': {
        WebkitTransform: 'translateY(-3px)',
        MozTransform: 'translateY(-3px)',
        msTransform: 'translateY(-3px)',
        OTransform: 'translateY(-3px)',
        transform: 'translateY(-3px)',
        WebkitBoxShadow: '0px 8px 15px 0px rgba(0, 0, 0, 0.2)',
        MozBoxShadow: '0px 8px 15px 0px rgba(0, 0, 0, 0.2)',
        boxShadow: '0px 8px 15px 0px rgba(0, 0, 0, 0.2)',
    }
}))

export default ButtonWrapper