import React from 'react'
import { Link } from 'react-router-dom'
import { Box, Stack, Typography } from '@mui/material'
import BackgroundPaper from '../Wrapper/BackgroundPaper'
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';

const AppBarBack = () => {
    return (
        <BackgroundPaper elevation={8} sx={{ p: 0 }}>
            <Box sx={{ m: 1 }}>
                <Stack direction='row' spacing={1} alignContent='center'>
                    <Link to="/" style={{ textDecoration: 'none' }}>
                        <ArrowBackIosNewOutlinedIcon fontSize='large' sx={{ color: 'common.white', my: 0.2 }} />
                    </Link>
                    <Link to="/" style={{ textDecoration: 'none' }}>
                        <Typography component='p' variant='h4' color={'common.white'} fontWeight='bold'>Back</Typography>
                    </Link>
                </Stack>
            </Box>
        </BackgroundPaper>
    )
}

export default AppBarBack