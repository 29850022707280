import { Link, Stack, Typography } from '@mui/material'
import React from 'react'
import BackgroundPaper from '../Wrapper/BackgroundPaper'

const Footer = () => {
    return (
        <BackgroundPaper elevation={8} sx={{ px: 2, py: 1.5 }}>

            <Stack spacing={0} sx={{ textAlign: 'end' }}>
                <Typography variant="p" color={'common.white'} sx={{ fontWeight: 'bold' }}>
                    Designed and Developed by
                    <Typography variant="p" component='span' color={'error.main'} sx={{ fontWeight: 'bold', mx: 1 }}>
                        <Link href='' color='inherit' sx={{ textDecoration: 'none' }}>
                            Ari Suseno
                        </Link>
                    </Typography>
                </Typography>
                <Typography variant="p" color={'common.white'} sx={{ fontWeight: 'bold' }}>
                    Clone ideo from
                    <Typography variant="p" component='span' color={'error.main'} sx={{ fontWeight: 'bold', mx: 1 }}>
                        <Link href='https://themeforest.net/user/pxlsolutions' target='__blank' color='inherit' sx={{ textDecoration: 'none' }}>
                            PxlSolutions
                        </Link>
                    </Typography>
                </Typography>

            </Stack>

        </BackgroundPaper>
    )
}

export default Footer