import {
  AutoAwesomeOutlined,
  EmojiEventsOutlined,
  GitHub,
  GridViewOutlined,
  HomeOutlined,
  LinkedIn,
  LocationOnOutlined,
  MailOutline,
  PermIdentityOutlined,
  QuestionAnswerOutlined,
  Telegram,
  WorkspacePremiumOutlined,
} from "@mui/icons-material";
import React from "react";

const resume = {
  about: {
    headline: "Web Developer. #Laravel, #ReactJs and #Node.js",
    job: "Web Developer",
    description: `<div class="Profile_about__Q_Z_r">
                    <p>I am Ari Suseno, web developer from Yogyakarta, Indonesia.</p>
                    <p>Passionate, task-driven Web Developer with 3+ years of experience in the education space. I help universities like Universitas Muhammadiyah Yogyakarta (UMY) and Universitas Siber Muhammadiyah (SiberMU) to increase workflow efficiency by 40%+.</p>
                    <p>𝗧𝗲𝗰𝗵𝗻𝗶𝗰𝗮𝗹 𝗦𝗸𝗶𝗹𝗹𝘀 &amp; 𝗧𝗼𝗼𝗹𝘀: Node.js, ReactJS, Laravel, &amp; SQL Server</p>
                    <p>Here are a few examples of my work:</p>
                    <ul style="padding:0;">
                      <li>Integrating the helpdesk website with WhatsApp has succeeded in reducing the number of queues at the front office by up to 50%, and can analyze data by type of complaint as a policy consideration.</li>
                      <li>Prevent loss of laboratory inventory and simplify procedures for submitting research by developing a website that can run automatically and systematically, resulting in a decrease in losses to 0%.</li>
                    </ul>
                    <p>Additionally, I won 1st place in the best IT Banking (Back End Developer) organized by the Ministry of Communication and Information Technology of the Republic of Indonesia,  beating 54 other participants.</p>
                    <p>I get excited about opportunities where I'm able to work with people who are passionate about developing websites that have a real impact on users.</p>
                    <p>I love connecting with new people, you can reach me at <span><a style="text-decoration:none; color: white; font-weight:bolder;" href="mailto:hello@arcen.me">hello@arcen.me</a></span>  </p>
                  </div>`,
    name: "Ari Suseno",
    birthday: "9 November 1998",
    location: "Yogyakarta, Indonesia",
    email: "hello@arcen.me",
    foto: require("../assets/images/profile.jpg"),
    cv: require("../assets/files/portfolio.pdf"),
  },
  experience: [
    {
      title: "Web Developer",
      company: "Universitas Muhammadiyah Yogyakarta",
      date: "Dec 2020 - Present",
      description: `• Develop a website for monitoring REST APIs, Access Points, and reminders of important dates. \n
      • Develop a website (NeoFeeder) to synchronize Universitas Muhammadiyah Yogyakarta data to PDDikti. \n
      • Develop a thesis monitoring website (sinthesis). \n
      • Develop a website (UMY SCADA) to manage Merdeka Belajar Kampus Merdeka (MBKM) program questionnaire. \n
      • Develop a website to manage medical expenses for UMY employees or their families with hospital partners. \n
      • Crawling UMY lecturer journal data from the website https://sinta.ristekbrin.go.id \n
      • Developed REST API for broadcasting WhatsApp messages. \n
      • Developed REST API to send and create emails with Universitas Siber Muhammadiyah (SiberMU) domain. \n
      • Develop a website and REST API (UMY Sehat) to help prevent Covid-19. \n
      • Develop REST API for internet quota claims for students with Telkomsel and Indosat Ooredoo partners. \n
      • Develop a website (SIMLabAgro) to manage the Laboratory of the Faculty of Agrotechnology and assist student research. \n
      • Develop a helpdesk website that is integrated with WhatsApp admin for every bureau/institution at Universitas Muhammadiyah Yogyakarta.`,
      skills: "Microsoft SQL Server · Laravel · React.js · Node.js",
    },
    {
      title: "Research Assistant",
      company: "Universitas Muhammadiyah Yogyakarta",
      date: "Mar 2020 - Jul 2020",
      description:
        "Responsible for assisting research for Indonesian culinary detection applications using a Single Shot MultiBox Detector (SSD) model to display nutritional value content.",
      skills:
        "Python (Programming Language) · Deep Learning · TensorFlow · Flutter",
    },
    {
      title: "Assistant Lecturer",
      company: "Universitas Muhammadiyah Yogyakarta",
      date: "Feb 2020 - Jun 2020",
      description:
        "Assistant Lecturer for Software Testing and Quality Assurance Course.",
      skills: "User Acceptance Testing · Debugging · Usability Testing",
    },
    {
      title: "Intern",
      company: "PT Gamatechno Indonesia",
      date: "Jul 2019 - Nov 2019",
      description:
        "As an intern, I was challenged to create a website that aims to manage employee attendance. I used CodeIgniter framework to complete this internship.",
      skills: "jQuery · MySQL · CodeIgniter · PHP",
    },
    {
      title: "Assistant Lecturer",
      company: "Universitas Muhammadiyah Yogyakarta",
      date: "Sep 2017 - Jan 2018",
      description: "Assistance Lecturer for Operating System Course.",
      skills: "Operating System Installation · Bitlocker",
    },
  ],
  certification: [
    {
      title: "Digital Talent Scholarship: IT Banking (Back-End)",
      organization:
        "Ministry of Communication and Information Technology of the Republic of Indonesia",
      issued: "Sep 2020",
      expires: "",
      credential_id: "-",
      credential_link: require("../assets/files/digitalent.pdf"),
    },
    {
      title: "Software Development",
      organization: "Badan Nasional Sertifikasi Profesi (BNSP)",
      issued: "Aug 2020",
      expires: "Aug 2023",
      credential_id: "62019 2514 5242 2020",
      credential_link: require("../assets/files/BNSP.pdf"),
    },
  ],
  socials: [
    {
      name: "Email address",
      link: "mailto: ",
      text: "hello@arcen.me",
      icon: <MailOutline sx={{ width: "100%", height: "100%" }} />,
    },
    {
      name: "Telegram",
      link: "https://t.me/",
      text: "arcenme",
      icon: <Telegram sx={{ width: "100%", height: "100%" }} />,
    },
    {
      name: "LinkedIn",
      link: "https://www.linkedin.com/in/",
      text: "arcenme",
      icon: <LinkedIn sx={{ width: "100%", height: "100%" }} />,
    },
    {
      name: "Github",
      link: "https://github.com/",
      text: "arcenme",
      icon: <GitHub sx={{ width: "100%", height: "100%" }} />,
    },
    {
      name: "Location",
      link: "",
      text: "Bantul, Yogyakarta, Indonesia",
      icon: <LocationOnOutlined sx={{ width: "100%", height: "100%" }} />,
    },
  ],
  portfolio: [
    {
      slug: "neo-feeder",
      title: "Neo Feeder",
      description:
        "NeoFeeder is used to synchronize data from Universitas Muhammadiyah Yogyakarta to the PDDikti database.",
      links: [
        {
          name: "Laravel",
          url: "",
          icon: require("../assets/images/devicon/laravel.png"),
        },
      ],
      thumbnail: require("../assets/works/neo_feeder/neo_feeder_1.png"),
      images: [
        {
          original: require("../assets/works/neo_feeder/neo_feeder_1.png"),
        },
        {
          original: require("../assets/works/neo_feeder/neo_feeder_2.png"),
        },
        {
          original: require("../assets/works/neo_feeder/neo_feeder_3.png"),
        },
      ],
    },
    {
      slug: "sinthesis",
      title: "SinThesis",
      description:
        "SinThesis aims to manage the thesis of UMY students. Starting from submission of the thesis, and guidance until the exam is complete.",
      links: [
        {
          name: "Laravel",
          url: "",
          icon: require("../assets/images/devicon/laravel.png"),
        },
      ],
      thumbnail: require("../assets/works/sinthesis/sinthesis_1.png"),
      images: [
        {
          original: require("../assets/works/sinthesis/sinthesis_1.png"),
        },
        {
          original: require("../assets/works/sinthesis/sinthesis_2.png"),
        },
        {
          original: require("../assets/works/sinthesis/sinthesis_3.png"),
        },
        {
          original: require("../assets/works/sinthesis/sinthesis_4.png"),
        },
        {
          original: require("../assets/works/sinthesis/sinthesis_5.png"),
        },
      ],
    },
    {
      slug: "umy-sehat",
      title: "UMY Sehat",
      description:
        "Created using ReactJS library, this website is used for recording Covid-19 cases at Universitas Muhammadiyah Yogyakarta.",
      links: [
        {
          name: "ReactJs",
          url: "",
          icon: require("../assets/images/devicon/react.png"),
        },
        {
          name: "Node.js",
          url: "",
          icon: require("../assets/images/devicon/node-js.png"),
        },
        {
          name: "Express",
          url: "",
          icon: require("../assets/images/devicon/express.png"),
        },
      ],
      thumbnail: require("../assets/works/umy_sehat/umy_sehat_1.png"),
      images: [
        {
          original: require("../assets/works/umy_sehat/umy_sehat_1.png"),
        },
        {
          original: require("../assets/works/umy_sehat/umy_sehat_2.png"),
        },
        {
          original: require("../assets/works/umy_sehat/umy_sehat_3.png"),
        },
      ],
    },
    {
      slug: "simlab-agro",
      title: "SIM Laboratorim Agroteknologi",
      description:
        "SIM Laboratorim Agroteknologi (SIMLabAgro) is used by the Faculty of Agrotechnology UMY for inventory management in the laboratory, recording student research, and consulting students with research supervisors.",
      links: [
        {
          name: "Laravel",
          url: "",
          icon: require("../assets/images/devicon/laravel.png"),
        },
      ],
      thumbnail: require("../assets/works/simlab_agro/simlab_agro_1.png"),
      images: [
        {
          original: require("../assets/works/simlab_agro/simlab_agro_1.png"),
        },
        {
          original: require("../assets/works/simlab_agro/simlab_agro_2.png"),
        },
        {
          original: require("../assets/works/simlab_agro/simlab_agro_3.png"),
        },
        {
          original: require("../assets/works/simlab_agro/simlab_agro_4.png"),
        },
        {
          original: require("../assets/works/simlab_agro/simlab_agro_5.png"),
        },
        {
          original: require("../assets/works/simlab_agro/simlab_agro_6.png"),
        },
        {
          original: require("../assets/works/simlab_agro/simlab_agro_7.png"),
        },
      ],
    },
    {
      slug: "helpdesk",
      title: "Helpdesk UMY",
      description:
        "Helpdesk UMY is used by students and staff of Universitas Muhammadiyah Yogyakarta (UMY) to report complaints to admin bureaus or institutions at UMY. Helpdesk UMY is also integrated with WhatsApp application.",
      links: [
        {
          name: "ReactJs",
          url: "",
          icon: require("../assets/images/devicon/react.png"),
        },
        {
          name: "Node.js",
          url: "",
          icon: require("../assets/images/devicon/node-js.png"),
        },
        {
          name: "Express",
          url: "",
          icon: require("../assets/images/devicon/express.png"),
        },
      ],
      thumbnail: require("../assets/works/helpdesk/helpdesk_1.png"),
      images: [
        {
          original: require("../assets/works/helpdesk/helpdesk_1.png"),
        },
        {
          original: require("../assets/works/helpdesk/helpdesk_2.png"),
        },
        {
          original: require("../assets/works/helpdesk/helpdesk_3.png"),
        },
        {
          original: require("../assets/works/helpdesk/helpdesk_4.png"),
        },
      ],
    },
    {
      slug: "tea-party",
      title: "The TeaParty Project",
      description:
        "The TeaParty Project (Code Name) is a research project on Indonesian culinary detection using the TensorFlow library and Single Shot MultiBox Detector model.The training results are converted into TensorFlow Lite form so that it can be used on mobile devices and then displays the nutritional value of the culinary. By using 11.939 images from 50 types of culinary, this application can recognize with an accuracy of up to 92%.",
      links: [
        {
          name: "TensorFlow",
          url: "",
          icon: require("../assets/images/devicon/tensorflow.png"),
        },
        {
          name: "Flutter",
          url: "",
          icon: require("../assets/images/devicon/flutter.png"),
        },
        {
          name: "Single Shot MultiBox Detector",
          url: "",
          icon: "",
        },
      ],
      thumbnail: require("../assets/works/tea_party/tea_party_3.png"),
      images: [
        {
          original: require("../assets/works/tea_party/tea_party_1.png"),
        },
        {
          original: require("../assets/works/tea_party/tea_party_2.png"),
        },
        {
          original: require("../assets/works/tea_party/tea_party_3.png"),
        },
      ],
    },
    {
      slug: "eoviz-presence",
      title: "Eoviz Presence",
      description:
        "Eoviz Presence is an application aimed to manage employee attendance. My responsibility in this project is to create an admin dashboard to manage user and employee attendance and also create a website landing page.",
      links: [
        {
          name: "CodeIgniter",
          url: "",
          icon: require("../assets/images/devicon/codeigniter.png"),
        },
      ],
      thumbnail: require("../assets/works/eoviz_presence/eoviz_presence_1.png"),
      images: [
        {
          original: require("../assets/works/eoviz_presence/eoviz_presence_1.png"),
        },
        {
          original: require("../assets/works/eoviz_presence/eoviz_presence_2.png"),
        },
        {
          original: require("../assets/works/eoviz_presence/eoviz_presence_3.png"),
        },
        {
          original: require("../assets/works/eoviz_presence/eoviz_presence_4.png"),
        },
      ],
    },
  ],
  awards: [
    {
      title:
        "Best Participant UI #1 Digital Talent Scholarship: IT Banking (Back End)",
      issuer:
        "Ministry of Communication and Information Technology of the Republic of Indonesia",
      issued: "Dec 2020",
      description: "https://youtu.be/ly8K5xz5NVU?t=4301",
    },
  ],
  menu: [
    { title: "Home", icon: <HomeOutlined /> },
    { title: "About", icon: <PermIdentityOutlined /> },
    { title: "Experience", icon: <AutoAwesomeOutlined /> },
    { title: "Certifications", icon: <WorkspacePremiumOutlined /> },
    { title: "Awards", icon: <EmojiEventsOutlined /> },
    { title: "Works", icon: <GridViewOutlined /> },
    { title: "Contact", icon: <QuestionAnswerOutlined /> },
  ],
};

export default resume;
