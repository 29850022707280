import { Box, Grid, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React, { Fragment } from 'react'
import BackgroundPaper from './../Wrapper/BackgroundPaper'
import ProfileText from './../Wrapper/ProfileText'
import TitleText from './../Wrapper/TitleText'
import HoverButton from './../Wrapper/HoverButton'
import resume from '../../utils/resume'
import { CloudDownloadOutlined, MailOutline } from '@mui/icons-material'
import { Element } from 'react-scroll'

const About = () => {

    const handleClick = (link) => {
        window.open(link);
    }

    return (
        <Element name="refAbout" className="element"  >
            <BackgroundPaper elevation={8}>
                <TitleText component='h2' variant='h5' >About Me</TitleText>
                <Grid container spacing={6} >
                    <Grid item xs='auto'>
                        <Box component='img' alt="foto" src={resume.about.foto} sx={{ height: 150, width: 150 }}></Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9}>
                        <Stack direction='column' spacing={2}>
                            <Typography component='h3' variant='h4' fontWeight={'bolder'}>Hello,</Typography>
                            <Fragment>
                                <div dangerouslySetInnerHTML={{ __html: resume.about.description }} />
                            </Fragment>
                            <Grid container>
                                <Grid item xs={12} sm={6} md={6}>
                                    <ProfileText name='Name' value={resume.about.name} />
                                    <ProfileText name='Birthday' value={resume.about.birthday} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <ProfileText name='Location' value={resume.about.location} />
                                    <ProfileText name='Email' value={resume.about.email} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={0}>
                                <Grid item xs={12} sm='auto' sx={{ mr: 3, mb: 2 }}>
                                    <HoverButton variant="contained" size='large' color='error' startIcon={<CloudDownloadOutlined />} onClick={handleClick.bind(this, resume.about.cv)} >
                                        Download CV
                                    </HoverButton>
                                </Grid>
                                <Grid item xs={12} sm='auto'>
                                    <a
                                        href={`mailto: ${resume.about.email}`}
                                        rel="noreferrer"
                                        style={{ textDecoration: 'none' }}>
                                        <HoverButton variant='outlined' size='large' color='general' startIcon={<MailOutline />}>
                                            Hire Me
                                        </HoverButton>
                                    </a>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Grid>
                </Grid>
            </BackgroundPaper>
        </Element>
    )
}

export default About