import React from 'react';
import { Box, Card, CardActionArea, CardMedia, Container, Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import BackgroundPaper from './../Wrapper/BackgroundPaper'
import TitleText from './../Wrapper/TitleText'
import { Element } from 'react-scroll'
import resume from '../../utils/resume'
import { Link } from 'react-router-dom'

const PortfolioItem = styled(Box)({
    position: 'relative',
    overflow: 'hidden',
    WebkitTransition: 'all 0.3s ease-in-out',
    MozTransition: 'all 0.3s ease-in-out',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
        webkitBoxShadow: '0px 6px 15px 0px rgba(0, 0, 0, 0.15)',
        MozBoxShadow: '0px 6px 15px 0px rgba(0, 0, 0, 0.15)',
        boxShadow: '0px 6px 15px 0px rgba(0, 0, 0, 0.15)',
        '& .portfolio-mask': {
            opacity: 0.9
        },
        '& .portfolio-image': {
            webkitBoxShadow: '0px 6px 15px 0px rgba(0, 0, 0, 0.15)',
            MozBoxShadow: '0px 6px 15px 0px rgba(0, 0, 0, 0.15)',
            boxShadow: '0px 6px 15px 0px rgba(0, 0, 0, 0.15)',
            WebkitTransform: 'scale(1)',
            MozTransform: 'scale(1)',
            msTransform: 'scale(1)',
            OTransform: 'scale(1)',
            transform: 'scale(1)',
        }, '& .portfolio-title': {
            opacity: 1,
            transform: 'translateY(0)'
        }
    }
})

const PortfolioTitle = styled(Typography)({
    margin: '0 0 4px',
    opacity: 0,
    transform: 'translateY(30px)',
    transition: 'all cubic-bezier(0.075, 0.82, 0.165, 1) 1s'
})

const PortfolioImage = styled(CardMedia)({
    WebkitTransform: 'scale(1)',
    MozTransform: 'scale(1)',
    msTransform: 'scale(1)',
    OTransform: 'scale(1)',
    transform: 'scale(1)',
    WebkitTransition: 'all 0.3s ease-in-out',
    MozTransition: 'all 0.3s ease-in-out',
    transition: 'all 0.3s ease-in-out',
})

const PortfolioMask = styled(Container)(({ theme }) => ({
    backgroundColor: theme.palette.error.main,
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    opacity: 0,
    WebkitTransition: 'all 0.3s ease-in-out',
    MozTransition: 'all 0.3s ease-in-out',
    transition: 'all 0.3s ease-in-out',
}))

const Portfolio = () => {
    return (
        <Element name="refWorks" className="element" id='section-portfolio' >
            <BackgroundPaper elevation={8} >
                <TitleText component='h2' variant='h5' >Portfolio</TitleText>
                <Grid container spacing={2} alignItems="center" >
                    {resume.portfolio.map((portfolio, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                            <Link to={`/works/${portfolio.slug}`}>
                                <Card>
                                    <CardActionArea>
                                        <PortfolioItem>
                                            <Box sx={{
                                                position: 'absolute',
                                                padding: '0 25px',
                                                top: '25px',
                                                width: '100%',
                                                zIndex: 1,
                                            }}>
                                                <PortfolioTitle className="portfolio-title" variant="h6">
                                                    {portfolio.title}
                                                </PortfolioTitle>
                                            </Box>
                                            <Box sx={{ overflow: 'hidden' }}>
                                                <PortfolioImage
                                                    className='portfolio-image'
                                                    component="img"
                                                    height="140"
                                                    image={portfolio.thumbnail}
                                                    alt={portfolio.title}
                                                />
                                                <PortfolioMask className="portfolio-mask"> </PortfolioMask>
                                            </Box>
                                        </PortfolioItem>

                                    </CardActionArea>
                                </Card>
                            </Link>
                        </Grid>
                    ))}

                </Grid>
            </BackgroundPaper>
        </Element>
    )
}

export default Portfolio