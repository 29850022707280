import { IconButton } from '@mui/material'
import React from 'react'
import BackgroundPaper from '../Wrapper/BackgroundPaper'
import { Menu } from '@mui/icons-material/';
import { Box } from '@mui/system';

const AppBarMobile = ({ push, setPush }) => {

    const handlePush = () => {
        setPush(!push)
    }

    return (
        <BackgroundPaper elevation={8} sx={{
            WebkitTransform: `translateX(0)`,
            MozTransform: `translateX(0)`,
            msTransform: `translateX(0)`,
            OTransform: `translateX(0)`,
            transform: `translateX(0)`,
            padding: 1,
        }}>
            <Box>
                <IconButton fontSize='large' onClick={handlePush}>
                    <Menu fontSize='inherit' />
                </IconButton>
            </Box>
        </BackgroundPaper>
    )
}

export default AppBarMobile