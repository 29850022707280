import { GridView, MailOutline } from '@mui/icons-material'
import { Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import BackgroundPaper from './../Wrapper/BackgroundPaper'
import HoverButton from './../Wrapper/HoverButton'
import resume from '../../utils/resume'
import { Link as LinkScroll } from 'react-scroll'
import { Element } from 'react-scroll'

const Home = () => {
    return (
        <Element name="refHome" className="element"  >
            <BackgroundPaper elevation={8} sx={{
                minHeight: 620,
                maxHeight: 620,
            }} >
                <Stack direction='column' spacing={2} justifyContent='center' sx={{ mx: 2 }}>
                    <Typography component='h1' variant='h2' fontWeight='bold'>I'm {resume.about.name}</Typography>
                    <Typography component='p' variant='h5'>{resume.about.headline}</Typography>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm='auto' sx={{ mr: 3, mb: 2 }}>
                            <HoverButton variant="contained" size='large' color='error' startIcon={<GridView />} >
                                <LinkScroll activeClass="active" className="refWorks" to="refWorks" spy={true} smooth={true} duration={700} isDynamic={true} offset={-50}>
                                    View Portfolio
                                </LinkScroll>
                            </HoverButton>
                        </Grid>
                        <Grid item xs={12} sm='auto'>
                            <a
                                href={`mailto: ${resume.about.email}`}
                                rel="noreferrer"
                                style={{ textDecoration: 'none' }}>
                                <HoverButton variant='outlined' size='large' color='general' startIcon={<MailOutline />}>
                                    Hire Me
                                </HoverButton>
                            </a>
                        </Grid>
                    </Grid>
                </Stack>
            </BackgroundPaper >
        </Element>
    )
}

export default Home