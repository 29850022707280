import {
  Container,
  createTheme,
  CssBaseline,
  ThemeProvider,
} from "@mui/material";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Portfolio from "./pages/Porfolio/Portfolio";
import Resume from "./pages/Resume/Resume";

function App() {
  // const [mode, setMode] = useState("dark");
  const mode = "dark";

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      background: {
        default: "#353353",
        paper: "#302f4e",
      },
      error: {
        main: "#f97b8b",
      },
      warning: {
        main: "#f9d74c",
      },
      general: {
        main: "#fff",
      },
    },
    typography: {
      button: {
        textTransform: "capitalize",
      },
    },
  });

  return (
    <ThemeProvider theme={mode === "dark" ? darkTheme : darkTheme}>
      <CssBaseline />
      <Container maxWidth="lg" sx={{ my: 3 }}>
        <Router>
          <Routes>
            <Route path="/" element={<Resume />} />
            <Route path="/works/:slug" element={<Portfolio />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Router>
      </Container>
    </ThemeProvider>
  );
}

export default App;
