import { KeyboardArrowUp } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react'
import useWindowDimensions from '../../components/Hook/useWindowDimensions';
import About from "../../components/Sections/About";
import AppBarMobile from '../../components/Sections/AppBarMobile';
import Certification from "../../components/Sections/Certification";
import Contact from "../../components/Sections/Contact";
import Experience from "../../components/Sections/Experience";
import Footer from '../../components/Sections/Footer';
import Home from "../../components/Sections/Home";
import Portfolio from "../../components/Sections/Portfolio";
import Sidebar from '../../components/Sections/Sidebar';
import { animateScroll as scroll } from 'react-scroll'
import Award from '../../components/Sections/Award';

const Resume = () => {
  const { width } = useWindowDimensions();
  const [push, setPush] = useState(width > 1200)

  useEffect(() => {
    setPush(width > 1200)
  }, [width])

  const scrollToTop = () => {
    scroll.scrollToTop()
  }

  return (
    <>
      <Sidebar push={push} setPush={setPush} />

      <Stack direction={"column"} spacing={3} sx={{
        margin: '50px 10px',
        width: `calc(100% - ${width > 1200 ? '256px' : '0'})`,
        WebkitTransition: 'all 0.3s ease-in-out',
        MozTransition: 'all 0.3s ease-in-out',
        transition: 'all 0.3s ease-in-out',
        WebkitTransform: `translateX(${push ? '259px' : '0'})`,
        MozTransform: `translateX(${push ? '259px' : '0'})`,
        msTransform: `translateX(${push ? '259px' : '0'})`,
        OTransform: `translateX(${push ? '259px' : '0'})`,
        transform: `translateX(${push ? '259px' : '0'})`,
      }}>
        {width <= 1200 ? <AppBarMobile push={push} setPush={setPush} /> : ''}
        <Home />
        <About />
        <Experience />
        <Certification />
        <Award />
        <Portfolio />
        <Contact />
        <Footer />
      </Stack>

      <IconButton sx={{
        zIndex: 4,
        backgroundColor: 'error.main',
        opacity: 0.6,
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        borderRadius: '50%',
        '&:hover': {
          backgroundColor: 'common.white',
          color: 'error.main'
        }
      }} onClick={scrollToTop}>
        <KeyboardArrowUp fontSize='medium' fontWeight='bolder' />
      </IconButton>
    </>
  )
}

export default Resume